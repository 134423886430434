import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _pick from 'lodash/pick'
// import _delay from 'lodash/delay'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import utils from '../utils'
import { AppContext } from '../AppProvider'

export const query = graphql`
	query HomePageQuery {
		prismic {
			home(uid: "home", lang: "en-gb") {
				...Home_page
			}
		}
	}
`

const Home = ({ data, location }) => {
	const [ cookies ] = useCookies([ 'promo_home_page' ])
	const { modal, openModal } = React.useContext(AppContext)
	const home = data.prismic.home

	if (!home) return null

	const { page_title, body, meta_title, meta_description, meta_image, meta_keywords } = home

	const popupProps = _pick(home, [
		'popup_enabled',
		'popup_title',
		'popup_description',
		'popup_background_image',
		'popup_awards_description',
    'popup_awards_logo',
    'popup_awards_title',
    'popup_background_gradient_left',
    'popup_background_gradient_right',
		'popup_supporting_image',
		'popup_video_button_image',
		'popup_vimeo_modal_title',
    'popup_vimeo_video_id'
	])

	const popupFlannelsProps = _pick(home, [
		'popup_flannels_enabled',
		'popup_flannels_background_image',
		'popup_flannels_logo_image',
		'popup_flannels_title',
		'popup_flannels_quote'
	])

	const popupFurnitureVillageProps = _pick(home, [
		'popup_furniturevillage_enabled',
		'popup_furniturevillage_title',
		'popup_furniturevillage_subtitle',
		'popup_furniturevillage_vimeo_video_id',
		'popup_furniturevillage_video_thumbnail'
	])

	const Body = (body) => _map(body, (slice, index) => utils.sliceRenderer({ ...slice, key: index, pageTitle: page_title }))

	const wasPromoModalActive = _get(cookies, 'promo_home_page')
	// Display CT popup
	if (popupProps.popup_enabled) {
		if (!modal.open && !wasPromoModalActive) {
			openModal({ type: 'promo', modalProps: popupProps })
		}
	}
	// Display Flannels popup
	if (popupFlannelsProps.popup_flannels_enabled) {
		if (!modal.open && !wasPromoModalActive) {
			openModal({ type: 'promoFlannels', modalProps: popupFlannelsProps })
		}
	}

	// Display Furniture Village popup
	if (popupFurnitureVillageProps.popup_furniturevillage_enabled) {
		if (!modal.open && !wasPromoModalActive) {
			openModal({ type: 'promoFurnitureVillage', modalProps: popupFurnitureVillageProps })
		}
	}
	

	return (
		<Layout>
			<SEO
				title={meta_title}
				description={meta_description}
				keywords={meta_keywords}
				image={meta_image}
				url={_get(location, 'href')} />
			<Body {...body} />
		</Layout>
	)
}

export default Home
